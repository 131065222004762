<template>
  <div>
    <v-chip
      class="mb-1 pb-1 pt-1"
      close
      close-icon="mdi-close"
      color="grey"
      @click:close="$emit('remove', true)"
      @mousedown.stop
      @click.stop>
      <div
        class="d-flex flex-wrap"
        style="height: 100%;">
        <div class="pr-2 align-self-center">
          {{ name }}
        </div>
        <div>
          <v-text-field
            v-model="searchVal"
            style="width: 90px;"
            class="pt-0 mt-0 search-filter__textfield"
            @input="$emit('update', { value: searchVal })" />
        </div>
      </div>
    </v-chip>
  </div>
</template>
<script>
export default {
  name: 'SearchFilterInputText',
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      required: true,
    },
    itemValue: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    searchVal: '',
  }),
  watch: {
    itemValue: {
      handler() {
        this.searchVal = this.itemValue;
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    this.searchVal = '';
  },
};
</script>
<style scoped lang="scss">
::v-deep .search-filter {
  &__textfield {
    height: 28px !important;
    .v-input__control {
      min-height: 28px !important;
      max-height: 28px !important;
      margin-bottom: 0 !important;
    }
    .v-input__slot {
      margin-bottom: 0 !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .v-icon.v-icon {
      font-size: 14px !important;
    }
    .v-input__prepend-inner {
      padding-right: 0 !important;
      cursor: pointer;
      & + .v-text-field__slot {
        input {
          margin-left: 0 !important;
        }
      }
    }
    input {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      margin-left: 6px !important;
      min-width: 40px !important;
      font-size: 14px !important;
      opacity: initial !important;
    }
  }
}
</style>
